import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

// HOME - BEGIN
import 'lazyload'
import lazyframe from 'lazyframe'
import 'lazyframe/dist/lazyframe.css'
import 'jquery-maskmoney'

import '../newstyle/css/home.scss'
import '../newstyle/css/custom.css'

import controllerCommon  from '../newstyle/scripts/controllers/controllerCommon.js'
import controllerSliders from '../newstyle/scripts/controllers/controllerSliders.js'
import lazyContent from '../utils/lazyContent.js'

window.controllerSliders = controllerSliders;

document.addEventListener("turbolinks:load", function() {
  lazyload();
  lazyframe(".lazyframe");
  lazyContent.init('home_grid');
  lazyContent.init('simulator_form');
  controllerCommon.init();

  // SIMULAÇÃO
  $('.home_simulator-form-book_type_radio input').change(function() {
    $.get('/simulations/set_book_format.js', {'page_format_id': $(this).val()});
  });
  $('.money').maskMoney({prefix: "", allowZero: true, allowNegative: true, thousands: ".", decimal: ",", affixesStay: false});

});
// HOME - END

Rails.start()
Turbolinks.start()
